import 'reflect-metadata';
import { createApp } from 'vue';
import App from '@/App.vue';

// Pinia
import { createPinia } from 'pinia';

// Router
import router from '@/router';

// Google Analytics
import VueGtag from 'vue-gtag';

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// PrimeVue
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import FocusTrap from 'primevue/focustrap';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';

import 'primevue/resources/themes/arya-purple/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

// Global Components
import MInput from '@/submodules/shared-front/src/components/utils/MInput.vue';
import MAvatar from '@/submodules/shared-front/src/components/utils/MAvatar.vue';
import MButton from '@/submodules/shared-front/src/components/utils/MButton.vue';
import MLoader from '@/submodules/shared-front/src/components/utils/MLoader.vue';
import MImage from '@/submodules/shared-front/src/components/utils/MImage.vue';

const app = createApp(App);

// Axios
app.use(VueAxios, axios);
app.axios.defaults.baseURL = process.env.VUE_APP_MAOB_URL;
app.provide('axios', app.config.globalProperties.axios);

// Pinia
const pinia = createPinia();
pinia.use(() => ({ $axios: app.axios, $router: router }));
app.use(pinia);

// Router
app.use(router);

// Global Components
app.component('MInput', MInput);
app.component('MAvatar', MAvatar);
app.component('MButton', MButton);
app.component('MLoader', MLoader);
app.component('MImage', MImage);

// PrimeVue
app.use(PrimeVue, { theme: 'arya-purple' });
app.use(ToastService);
app.use(ConfirmationService);
app.directive('tooltip', Tooltip);
app.directive('focustrap', FocusTrap);
app.directive('badge', BadgeDirective);

// Google Analytics
app.use(
	VueGtag,
	{
		appName: 'El Under MAOF',
		config: {
			id: process.env.VUE_APP_GA_MEASURMENT_ID
		}
	},
	router
);

app.mount('#app');
