import { defineStore } from 'pinia';
import axios from 'axios';
import { TEAM_MEMBER_ROLES } from '@/submodules/shared-front/src/utils/constants/Constants';
import {
	TeamWithPlayersAndTournamentsDetails,
	TeamWithPlayersDetails
} from '@/submodules/shared-types/teams/dtos/get-team.dto';
import { UpdateTeamRequestDto } from '@/submodules/shared-types/teams/dtos/update-team.dto';
import { CreateTeamRequestDto } from '@/submodules/shared-types/teams/dtos/create-team.dto';

interface TeamsState {
	_teams: TeamWithPlayersDetails[];
	_team: TeamWithPlayersAndTournamentsDetails | null;
}

export const useTeamsStore = defineStore('teams', {
	state: (): TeamsState => ({
		_teams: [],
		_team: null
	}),
	getters: {
		teams: (state) => state._teams,
		team: (state) => state._team
	},
	actions: {
		async fetchTeams() {
			const request = await axios.get('/teams');
			this._teams = request.data.teams;
		},

		async fetchTeam(teamUuid: string) {
			const request = await axios.get(`/teams/${teamUuid}`);
			this._team = request.data.team;
		},

		async fetchTeamMembers(teamUuid: string) {
			const request = await axios.get(`/teams/${teamUuid}/members`);
			const { members } = request.data;

			return members;
		},

		async createTeam(teamCreationData: CreateTeamRequestDto) {
			const { data } = await axios.post('/teams/create', {
				...teamCreationData
			});
			return data.team.uuid;
		},

		async deleteTeam(teamUuid: string) {
			await axios.delete(`/teams/${teamUuid}`);
		},

		async updateTeam(uuid: string, modificationData: UpdateTeamRequestDto) {
			try {
				await axios.patch(`/teams/${uuid}`, {
					...modificationData
				});
			} catch (error: any) {
				console.error('Failed to update team: ', error.response);

				const { message, statusCode } = error.response.data;

				let errorMessage =
					'No se pudieron guardar los cambios del equipo';

				if (statusCode === 400 || statusCode === 401)
					errorMessage = message;

				throw new Error(errorMessage);
			}
		},

		async updateTeamLogo(uuid: string, teamLogo: File) {
			const formData = new FormData();
			formData.append('file', teamLogo);
			await axios.patch(`/teams/${uuid}/logo`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
		},

		async addTeamMember(teamUuid: string, playerUuid: string) {
			await axios.put(`/teams/${teamUuid}/add/${playerUuid}`);
		},

		async removeTeamMember(teamUuid: string, playerUuid: string) {
			await axios.patch(`/teams/${teamUuid}/remove/${playerUuid}`);
		},

		// Promotes team member to be captain
		async promoteTeamMember(teamUuid: string, playerUuid: string) {
			await axios.patch(`/teams/${teamUuid}/promote/${playerUuid}`);
		},

		// Modify role to team member/s
		async modifyTeamMembers(
			teamUuid: string,
			members: { uuid: string; role?: keyof typeof TEAM_MEMBER_ROLES }[]
		) {
			await axios.patch(`/teams/${teamUuid}/membersRoles`, {
				members
			});
		},

		// Invite players to team using their uuids
		async invitePlayersToTeam(teamUuid: string, playersUuids: string[]) {
			await axios.post(`/invitations/teamInvitation`, {
				teamUuid,
				playersUuids
			});
		},

		// Add player to team
		async answerTeaminvitation({
			notificationId,
			teamUuid,
			playerUuid,
			acceptInvitation
		}: {
			notificationId: number;
			teamUuid: string;
			playerUuid: string;
			acceptInvitation: boolean;
		}) {
			await axios.patch(
				`/invitations/${notificationId}/team/${teamUuid}`,
				{
					playerUuid,
					acceptInvitation
				}
			);
		}
	}
});
